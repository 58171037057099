const { slugify } = require('transliteration');

var createUrlAlias = function(s) {
  slugify.config({
    replace: [
      ['кВ', ''],
      [' - ', '-'],
      ['нг(A)', 'nga'],
      ['нг(B)', 'ngb'],
      ['нг(С)', 'ngc'],
      [' ', '-'],
      ['х', 'x'],
      [',', '.'],
      ['1,0', '1'],
      ['2,0', '2'],
      ['3,0', '3'],
      ['4,0', '4'],
      ['5,0', '5'],
      ['6,0', '6'],
      ['8,0', '8']
    ]
  });
  return slugify(s)
}

module.exports.createUrlAlias = createUrlAlias