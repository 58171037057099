import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { createUrlAlias } from "../utils/url"

import indexImage from "../images/main_page_image_new.jpg"

import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  indexImageWrapper: {
    backgroundImage: "url(" + indexImage + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    width: "100vw",
    maxWidth: "1960px",
    // height: "100%",
    // position: "relative",
    // float: "left",
    marginLeft: "calc(-50vw + 50% - 8px)",
    // padding: "250px 0 50px 0",
    marginTop: "0",
    marginBottom: "0",
    // display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    '& h1': {
      fontSize: "3.3rem",
      padding: "250px 0 0 0",
      // [theme.breakpoints.down('md')]: {
      //   fontSize: "3.3rem",
      // },
      [theme.breakpoints.down('xs')]: {
        padding: "150px 0 0 0",
        fontSize: "2.2rem",
      },
      // marginTop: "-13vw",
      textAlign: "center",
      display: "block",
      margin: 0
    },
    '& h2': {
      fontSize: "1.7rem",
      padding: "0 0 50px 0",
      // [theme.breakpoints.down('md')]: {
      //   fontSize: "1.7rem",
      // },
      [theme.breakpoints.down('xs')]: {
        fontSize: "1rem",
        padding: "0 0 25px 0",
      },
      // marginTop: "-5vw",
      textAlign: "center",
      display: "block",
      margin: 0
    }
  },
  categoriesWrapper: {
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.down('md')]: {
      columnCount: "1"
    },
    [theme.breakpoints.up('md')]: {
      columnCount: "2",
      columnGap: "4rem",
      // columnWidth: "33%"
    },
    '& li': {
      padding: "0.5rem 0 0.5rem 0",
      breakInside: "avoid",
      // width: "33.3%",
      // float: "left"
    },
    '& a': {
      fontSize: "0.9rem",
      display: "block"
    }
  }
}))


const IndexPage = ({ data }) => {
  const classes = useStyles()
  return (
  <Layout>
    <SEO title="⚡Уралпромкабель — официальный сайт компании" />
    <div className={classes.indexImageWrapper}>
      <h1>Уралпромкабель</h1>
      <h2>поставщик электротехники с 2012 г.</h2>
    </div>
    <div className={classes.categoriesWrapper}>
      <ul>
      {data.allMongodbUpcablebaseCables.nodes.map(group => (
        <li>
          <Link to={`/catalog/${createUrlAlias(group.name)}/`}>{group.name}</Link>
        </li>
      ))}
      </ul>
    </div>
    <div>
      <p>Компания «Уралпромкабель» работает на электротехническом рынке и предлагает комплексные поставки
        кабельно-проводниковой и электротехнической продукции для строительных, электромонтажных организаций, для компаний работающих в
        нефтяной, химической и других отраслях промышленности.</p>
      <p>Ключевые особенности работы с нами:</p>
      <ul>
        <li>Наличие основных позиций продукции на складе.</li>
        <li>Соблюдение финансовой дисциплины, сроков исполнения заказа и конфиденциальности при работе с
          заказчиками.
        </li>
        <li>Наличие стабильной сети контрагентов; установление личных контактов с лицами, принимающими решения по
          исполнению сделки.
        </li>
        <li>Возможность полного закрытия потребности в продукции с размещением заказа на заводе-изготовителе.</li>
      </ul>
      <p>Будем рады видеть в числе наших партнеров!</p>
    </div>
  </Layout>
  )
}

export default IndexPage

export const query = graphql`
    query indexPageQuery {
      allMongodbUpcablebaseCables {
        nodes {
          name
        }
      }
    }
  `